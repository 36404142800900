define("discourse/plugins/chat/discourse/models/chat-channel", ["exports", "@glimmer/tracking", "pretty-text/guid", "discourse/lib/utilities", "discourse/models/category", "discourse-common/lib/get-owner", "discourse-common/lib/get-url", "discourse/plugins/chat/discourse/lib/chat-messages-manager", "discourse/plugins/chat/discourse/lib/chat-threads-manager", "discourse/plugins/chat/discourse/lib/slugify-channel", "discourse/plugins/chat/discourse/models/chat-channel-archive", "discourse/plugins/chat/discourse/models/chat-direct-message", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-tracking-state", "discourse/plugins/chat/discourse/models/user-chat-channel-membership"], function (_exports, _tracking3, _guid, _utilities, _category, _getOwner, _getUrl, _chatMessagesManager, _chatThreadsManager, _slugifyChannel, _chatChannelArchive, _chatDirectMessage, _chatMessage, _chatTrackingState, _userChatChannelMembership) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHATABLE_TYPES = _exports.CHANNEL_STATUSES = void 0;
  _exports.channelStatusIcon = channelStatusIcon;
  _exports.default = void 0;
  const CHATABLE_TYPES = _exports.CHATABLE_TYPES = {
    directMessageChannel: "DirectMessage",
    categoryChannel: "Category"
  };
  const CHANNEL_STATUSES = _exports.CHANNEL_STATUSES = {
    open: "open",
    readOnly: "read_only",
    closed: "closed",
    archived: "archived"
  };
  function channelStatusIcon(channelStatus) {
    if (channelStatus === CHANNEL_STATUSES.open) {
      return null;
    }
    switch (channelStatus) {
      case CHANNEL_STATUSES.closed:
        return "lock";
      case CHANNEL_STATUSES.readOnly:
        return "comment-slash";
      case CHANNEL_STATUSES.archived:
        return "archive";
    }
  }
  const STAFF_READONLY_STATUSES = [CHANNEL_STATUSES.readOnly, CHANNEL_STATUSES.archived];
  const READONLY_STATUSES = [CHANNEL_STATUSES.closed, CHANNEL_STATUSES.readOnly, CHANNEL_STATUSES.archived];
  class ChatChannel {
    static create() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new ChatChannel(args);
    }
    static #_ = (() => dt7948.g(this.prototype, "title", [_tracking3.tracked]))();
    #title = (() => (dt7948.i(this, "title"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "slug", [_tracking3.tracked]))();
    #slug = (() => (dt7948.i(this, "slug"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "description", [_tracking3.tracked]))();
    #description = (() => (dt7948.i(this, "description"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "status", [_tracking3.tracked]))();
    #status = (() => (dt7948.i(this, "status"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "activeThread", [_tracking3.tracked]))();
    #activeThread = (() => (dt7948.i(this, "activeThread"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "meta", [_tracking3.tracked]))();
    #meta = (() => (dt7948.i(this, "meta"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "chatableId", [_tracking3.tracked]))();
    #chatableId = (() => (dt7948.i(this, "chatableId"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "chatableType", [_tracking3.tracked]))();
    #chatableType = (() => (dt7948.i(this, "chatableType"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "chatableUrl", [_tracking3.tracked]))();
    #chatableUrl = (() => (dt7948.i(this, "chatableUrl"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "autoJoinUsers", [_tracking3.tracked]))();
    #autoJoinUsers = (() => (dt7948.i(this, "autoJoinUsers"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "allowChannelWideMentions", [_tracking3.tracked]))();
    #allowChannelWideMentions = (() => (dt7948.i(this, "allowChannelWideMentions"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "membershipsCount", [_tracking3.tracked]))();
    #membershipsCount = (() => (dt7948.i(this, "membershipsCount"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "archive", [_tracking3.tracked]))();
    #archive = (() => (dt7948.i(this, "archive"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "tracking", [_tracking3.tracked]))();
    #tracking = (() => (dt7948.i(this, "tracking"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "threadingEnabled", [_tracking3.tracked]))();
    #threadingEnabled = (() => (dt7948.i(this, "threadingEnabled"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "draft", [_tracking3.tracked]))();
    #draft = (() => (dt7948.i(this, "draft"), void 0))();
    threadsManager = (() => new _chatThreadsManager.default((0, _getOwner.getOwnerWithFallback)(this)))();
    messagesManager = (() => new _chatMessagesManager.default((0, _getOwner.getOwnerWithFallback)(this)))();
    static #_17 = (() => dt7948.g(this.prototype, "_currentUserMembership", [_tracking3.tracked]))();
    #_currentUserMembership = (() => (dt7948.i(this, "_currentUserMembership"), void 0))();
    static #_18 = (() => dt7948.g(this.prototype, "_lastMessage", [_tracking3.tracked]))();
    #_lastMessage = (() => (dt7948.i(this, "_lastMessage"), void 0))();
    constructor() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.id = args.id;
      this.chatableId = args.chatable_id;
      this.chatableUrl = args.chatable_url;
      this.chatableType = args.chatable_type;
      this.membershipsCount = args.memberships_count;
      this.slug = args.slug;
      this.title = args.title;
      this.unicodeTitle = args.unicode_title;
      this.status = args.status;
      this.description = args.description;
      this.threadingEnabled = args.threading_enabled;
      this.autoJoinUsers = args.auto_join_users;
      this.allowChannelWideMentions = args.allow_channel_wide_mentions;
      this.currentUserMembership = args.current_user_membership;
      this.lastMessage = args.last_message;
      this.meta = args.meta;
      this.iconUploadUrl = args.icon_upload_url ? (0, _getUrl.getURLWithCDN)(args.icon_upload_url) : null;
      this.chatable = this.#initChatable(args.chatable ?? []);
      this.tracking = new _chatTrackingState.default((0, _getOwner.getOwnerWithFallback)(this));
      if (args.archive_completed || args.archive_failed) {
        this.archive = _chatChannelArchive.default.create(args);
      }
    }
    get unreadThreadsCountSinceLastViewed() {
      return Array.from(this.threadsManager.unreadThreadOverview.values()).filter(lastReplyCreatedAt => lastReplyCreatedAt >= this.currentUserMembership.lastViewedAt).length;
    }
    get unreadThreadsCount() {
      return this.threadsManager.unreadThreadCount;
    }
    get watchedThreadsUnreadCount() {
      return this.threadsManager.threads.reduce((unreadCount, thread) => {
        return unreadCount + thread.tracking.watchedThreadsUnreadCount;
      }, 0);
    }
    updateLastViewedAt() {
      this.currentUserMembership.lastViewedAt = new Date();
    }
    get canDeleteSelf() {
      return this.meta.can_delete_self;
    }
    get canDeleteOthers() {
      return this.meta.can_delete_others;
    }
    get canFlag() {
      return this.meta.can_flag;
    }
    get userSilenced() {
      return this.meta.user_silenced;
    }
    get canModerate() {
      return this.meta.can_moderate;
    }
    get escapedTitle() {
      return (0, _utilities.escapeExpression)(this.title);
    }
    get escapedDescription() {
      return (0, _utilities.escapeExpression)(this.description);
    }
    get slugifiedTitle() {
      return this.slug || (0, _slugifyChannel.default)(this);
    }
    get routeModels() {
      return [this.slugifiedTitle, this.id];
    }
    get isDirectMessageChannel() {
      return this.chatableType === CHATABLE_TYPES.directMessageChannel;
    }
    get isCategoryChannel() {
      return this.chatableType === CHATABLE_TYPES.categoryChannel;
    }
    get isOpen() {
      return !this.status || this.status === CHANNEL_STATUSES.open;
    }
    get isReadOnly() {
      return this.status === CHANNEL_STATUSES.readOnly;
    }
    get isClosed() {
      return this.status === CHANNEL_STATUSES.closed;
    }
    get isArchived() {
      return this.status === CHANNEL_STATUSES.archived;
    }
    get isJoinable() {
      return this.isOpen && !this.isArchived;
    }
    get isFollowing() {
      return this.currentUserMembership.following;
    }
    get canJoin() {
      return this.meta.can_join_chat_channel;
    }
    get hasUnread() {
      return this.tracking.unreadCount + this.tracking.mentionCount + this.tracking.watchedThreadsUnreadCount + this.threadsManager.unreadThreadCount > 0;
    }
    async stageMessage(message) {
      message.id = (0, _guid.default)();
      message.staged = true;
      message.processed = false;
      message.draft = false;
      message.createdAt = new Date();
      message.channel = this;
      if (message.inReplyTo) {
        if (!this.threadingEnabled) {
          this.messagesManager.addMessages([message]);
        }
      } else {
        this.messagesManager.addMessages([message]);
      }
      message.manager = this.messagesManager;
    }
    resetDraft(user) {
      this.draft = _chatMessage.default.createDraftMessage(this, {
        user
      });
    }
    canModifyMessages(user) {
      if (user.staff) {
        return !STAFF_READONLY_STATUSES.includes(this.status);
      }
      return !READONLY_STATUSES.includes(this.status);
    }
    get currentUserMembership() {
      return this._currentUserMembership;
    }
    set currentUserMembership(membership) {
      if (membership instanceof _userChatChannelMembership.default) {
        this._currentUserMembership = membership;
      } else {
        this._currentUserMembership = _userChatChannelMembership.default.create(membership);
      }
    }
    get lastMessage() {
      return this._lastMessage;
    }
    set lastMessage(message) {
      if (!message) {
        this._lastMessage = null;
        return;
      }
      if (message instanceof _chatMessage.default) {
        this._lastMessage = message;
      } else {
        this._lastMessage = _chatMessage.default.create(this, message);
      }
    }
    #initChatable(chatable) {
      if (!chatable || chatable instanceof _category.default || chatable instanceof _chatDirectMessage.default) {
        return chatable;
      } else {
        if (this.isDirectMessageChannel) {
          return _chatDirectMessage.default.create({
            users: chatable?.users,
            group: chatable?.group
          });
        } else {
          return _category.default.create(chatable);
        }
      }
    }
  }
  _exports.default = ChatChannel;
});