import { template as template_e5496c11acfe4f9489778327bb22cdc0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e5496c11acfe4f9489778327bb22cdc0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
